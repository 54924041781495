.lottie-container {
    height: 75%;
    /* 75% de la hauteur de la fenêtre (ou du parent) */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Empêche l'animation de dépasser du conteneur */
}

.lottie-container>div {
    width: auto;
    height: 100%;
    /* L'animation prendra 100% de la hauteur de son conteneur */
}

ol > li {
    padding: 10px 0px
}

h1 {
    padding-bottom: 20px;
}